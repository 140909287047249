body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
}